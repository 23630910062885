/** @jsx jsx */
import { jsx } from 'theme-ui';

import MiniArticleCard from './MiniArticleCard';

const MiniArticleList = ({ posts, transparent, ...props }) => (
  <div
    {...props}
    sx={{
      display: 'grid',
      gridTemplateColumns: posts.length > 1 ? ['1fr', null, '1fr 1fr'] : '1fr',
      gridGap: 7,
    }}
  >
    {posts.map((post) => (
      <MiniArticleCard key={post.id} post={post} transparent={transparent} />
    ))}
  </div>
);

export default MiniArticleList;
