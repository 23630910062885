/** @jsx jsx */
import { jsx } from 'theme-ui';

const OutlinedText = (props) => (
  <div
    {...props}
    sx={{
      lineHeight: 1,
      fontWeight: 'bold',
      color: 'secondary',
      // color: 'transparent',
      // WebkitTextStrokeWidth: '2px',
      // WebkitTextStrokeColor: (t) => t.colors.secondary,
    }}
  />
);

export default OutlinedText;
