/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Link } from 'gatsby';
import Img from 'gatsby-image';

import { resetLink } from '../utils/styles';
import { getTypeLabel } from '../utils/articles';
import useCurrentLanguage from '../hooks/use-current-language';
import Truncate from './Truncate';

const MiniArticleCard = ({ post, transparent }) => {
  const lang = useCurrentLanguage();
  const path = post.fields.url;

  return (
    <article>
      <Link
        to={path}
        title={post.frontmatter.title}
        sx={{
          ...resetLink,

          bg: !transparent && 'gray1',
          borderRadius: '4px',
          display: 'grid',
          gridTemplateColumns: '160px auto',
        }}
      >
        <div
          sx={{
            p: 4,
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {post.frontmatter.thumbnail && (
            <div
              sx={{
                width: '100%',
              }}
            >
              <Img
                fluid={post.frontmatter.thumbnail.childImageSharp.fluid}
                loading="eager"
                fadeIn={false}
                imgStyle={{
                  objectFit: 'contain',
                }}
              />
            </div>
          )}
        </div>

        <div
          sx={{
            p: 4,
            pl: transparent && 0,
          }}
        >
          <div
            sx={{
              fontSize: '12px',
              textTransform: 'uppercase',
            }}
          >
            {getTypeLabel(post.fields.sourceInstanceName, lang)}
          </div>
          <div
            sx={{
              display: 'block',
              fontWeight: 'bold',
              'a:hover &': {
                color: 'secondary',
              },
            }}
          >
            <Truncate length={50}>{post.frontmatter.title}</Truncate>
          </div>
        </div>
      </Link>
    </article>
  );
};

export default MiniArticleCard;
