export function getTypeLabel(type, lang = 'en') {
  const labels = {
    en: {
      'news-posts': 'Press release',
      'blog-posts': 'Article',
      'tech-posts': 'Tech Insights',
    },
    fr: {
      'news-posts': 'Communiqué de presse',
      'blog-posts': 'Article',
      'tech-posts': 'Tech Insights',
    },
  };

  return labels[lang]?.[type];
}
