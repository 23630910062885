/** @jsx jsx */
import { jsx, Styled } from 'theme-ui';
import { Fragment } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import createGet from '../utils/create-get';

import headerWhySvg from '../img/headers/header-why.svg';

import Layout from '../components/Layout';
import Container from '../components/Container2';
import MarkdownContent from '../components/MarkdownContent';
import AspectRatioImg from '../components/AspectRatioImg';
import OutlinedText from '../components/OutlinedText';
import MiniArticleList from '../components/MiniArticleList';

const Related = ({ relatedPosts }) =>
  relatedPosts.length > 0 ? (
    <Container sx={{ mt: 14 }}>
      <MiniArticleList posts={relatedPosts} />
    </Container>
  ) : null;

export const WhyPageTemplate = ({ get, relatedPosts }) => (
  <Fragment>
    <Container>
      <AspectRatioImg src={headerWhySvg} alt="" ratio={912 / 256} />
      <Styled.h1 sx={{ mt: 13 }}>{get('title')}</Styled.h1>
      <MarkdownContent sx={{ mt: 7 }}>{get('text')}</MarkdownContent>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr 1fr',
          gridGap: 7,
          mt: 13,
        }}
      >
        {get('stats', []).map((stat, i) => (
          <div key={i}>
            <OutlinedText sx={{ fontSize: '90px' }}>
              {get(stat, 'value')}
            </OutlinedText>
            <div sx={{ mt: 4, fontSize: 14 }}>{get(stat, 'text')}</div>
          </div>
        ))}
      </div>
      <Styled.h2 sx={{ mt: 13 }}>{get('obstacles.title')}</Styled.h2>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridTemplateRows: '1fr',
          gridGap: '24px',
        }}
      >
        {get('obstacles.text', []).map((item, i) => (
          <div sx={{ mt: 7 }} key={i}>
            {item}
          </div>
        ))}
      </div>
      <div sx={{ mt: 8 }}>
        {get('obstacles.items', []).map((item, i) => (
          <div
            key={i}
            sx={{
              display: 'grid',
              gridGap: 7,
              gridTemplateColumns: 'auto 1fr 2fr',
              alignItems: 'center',
              '& + &': {
                mt: 7,
              },
            }}
          >
            <div sx={{ display: 'flex' }}>
              <OutlinedText sx={{ fontSize: '50px' }}>
                {item.value}
              </OutlinedText>
              <Styled.p sx={{ color: 'secondary' }}>*</Styled.p>
            </div>
            <div>{item.title}</div>
            <div
              sx={{
                bg: 'gray1',
                borderRadius: '4px',
                px: 5,
                py: 3,
              }}
            >
              <MarkdownContent>{item.solution}</MarkdownContent>
            </div>
          </div>
        ))}
      </div>
      <div sx={{ mt: 11 }}>
        <MarkdownContent sx={{ fontSize: '12px' }} space={2}>
          {get('obstacles.footnotes')}
        </MarkdownContent>
      </div>
    </Container>
    <Related relatedPosts={relatedPosts} />
  </Fragment>
);

const WhyPage = ({ data }) => {
  const {
    markdownRemark: { frontmatter },
    relatedPosts,
  } = data;
  return (
    <Layout
      page="why"
      seo={{
        title: frontmatter.pageTitle,
        description: frontmatter.description,
      }}
    >
      <WhyPageTemplate
        get={createGet(frontmatter)}
        relatedPosts={relatedPosts.nodes}
      />
    </Layout>
  );
};

WhyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default WhyPage;

export const pageQuery = graphql`
  query WhyPageQuery($id: String!, $language: String!, $currentDate: Date!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        pageTitle
        description
        title
        text
        stats {
          value
          text
        }
        obstacles {
          title
          text
          items {
            value
            title
            solution
          }
          footnotes
        }
      }
    }
    relatedPosts: allMdx(
      sort: { fields: frontmatter___date, order: DESC }
      limit: 2
      filter: {
        fields: { sourceInstanceName: { eq: "blog-posts" } }
        frontmatter: {
          tags: { in: ["why"] }
          language: { eq: $language }
          date: { lte: $currentDate }
        }
      }
    ) {
      nodes {
        id
        slug
        fields {
          url
          sourceInstanceName
        }
        frontmatter {
          title
          thumbnail {
            childImageSharp {
              fluid(maxWidth: 500) {
                ...GatsbyImageSharpFluid_noBase64
              }
            }
          }
        }
        timeToRead
      }
    }
  }
`;
